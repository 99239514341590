
<template>
  <div class="flex min-h-screen font-sans">
    <div class="flex flex-col items-center justify-center flex-1 px-4 py-12 sm:px-6 lg:flex-none lg:w-[800px] lg:px-20 xl:px-24 ">
      <div class="w-full lg:w-[475px]">
        <div class="w-24 ">
          <router-link
            to="/"
            class="w-24 h-24"
          >
            <img
              class="w-24 h-24"
              src="@/assets/logo.svg"
              alt="Logo"
            >
          </router-link>
        </div>

        <h3 class="mt-6 text-lg font-bold leading-6 text-white uppercase">
          LOGIN
        </h3>

        <h2 class="text-3xl font-extrabold text-primary">
          Welcome back, Traveller!
        </h2>

        <div class="mt-8">
          <div class="mt-6">
            <form
              action="#"
              method="POST"
              class="space-y-6"
            >
              <div>
                <label
                  for="email"
                  class="block text-sm font-medium text-white-300"
                >
                  Email address
                </label>
                <div class="mt-1">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autocomplete="email"
                    required=""
                    class="default-input"
                  >
                </div>
              </div>

              <div class="space-y-1">
                <label
                  for="password"
                  class="block text-sm font-medium text-white-300 "
                >
                  Password
                </label>
                <div class="mt-1">
                  <input
                    id="password"
                    name="password"
                    type="password"
                    autocomplete="current-password"
                    required=""
                    class="default-input"
                  >
                </div>
              </div>

              <div class="flex items-center justify-between">
                <div class="flex items-center">
                  <input
                    id="remember_me"
                    name="remember_me"
                    type="checkbox"
                    class="default-checkbox"
                  >
                  <label
                    for="remember_me"
                    class="block ml-2 text-sm text-gray-200"
                  >
                    Remember me
                  </label>
                </div>

                <div class="text-sm">
                  <router-link
                    to="/forgot"
                    class="font-medium text-gray-200"
                  >
                    Forgot your password?
                  </router-link>
                </div>
              </div>

              <div>
                <button
                  type="submit"
                  class="flex justify-center w-full px-4 py-2 text-sm font-medium text-black border border-transparent rounded-md shadow-sm bg-primary focus:outline-none focus:ring-2 focus:ring-offset-2"
                >
                  Sign in
                </button>
              </div>
            </form>
          </div>

          <div>
            <div class="relative mt-6">
              <div
                class="absolute inset-0 flex items-center"
                aria-hidden="true"
              >
                <div class="w-full border-t border-bg-200" />
              </div>
              <div class="relative flex justify-center text-sm">
                <span class="px-2 py-1 text-gray-200 rounded-lg bg-bg-dark">
                  Or continue with
                </span>
              </div>
            </div>
            <div>
              <div class="grid grid-cols-3 gap-3 mt-2 ">
                <div>
                  <button
                    class="social-button"
                  >
                    <span class="sr-only">Sign in with Facebook</span>
                    <!-- Simple Icons: Facebook -->
                    <svg
                      class="w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                    >
                      <path d="M24 12.073c0-6.627-5.373-12-12-12s-12 5.373-12 12c0 5.99 4.388 10.954 10.125 11.854v-8.385H7.078v-3.47h3.047V9.43c0-3.007 1.792-4.669 4.533-4.669 1.312 0 2.686.235 2.686.235v2.953H15.83c-1.491 0-1.956.925-1.956 1.874v2.25h3.328l-.532 3.47h-2.796v8.385C19.612 23.027 24 18.062 24 12.073z" />
                    </svg>
                  </button>
                </div>

                <div>
                  <button
                    href="#"
                    class="social-button"
                  >
                    <span class="sr-only">Sign in with Twitter</span>
                    <!-- Simple Icons: Twitter -->
                    <svg
                      class="w-5 h-5"
                      aria-hidden="true"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path d="M23.953 4.57a10 10 0 01-2.825.775 4.958 4.958 0 002.163-2.723c-.951.555-2.005.959-3.127 1.184a4.92 4.92 0 00-8.384 4.482C7.69 8.095 4.067 6.13 1.64 3.162a4.822 4.822 0 00-.666 2.475c0 1.71.87 3.213 2.188 4.096a4.904 4.904 0 01-2.228-.616v.06a4.923 4.923 0 003.946 4.827 4.996 4.996 0 01-2.212.085 4.936 4.936 0 004.604 3.417 9.867 9.867 0 01-6.102 2.105c-.39 0-.779-.023-1.17-.067a13.995 13.995 0 007.557 2.209c9.053 0 13.998-7.496 13.998-13.985 0-.21 0-.42-.015-.63A9.935 9.935 0 0024 4.59z" />
                    </svg>
                  </button>
                </div>

                <div>
                  <button
                    href="#"
                    class="social-button"
                  >
                    <span class="sr-only">Sign in with Google</span>
                    <svg
                      class="w-5 h-5"
                      aria-hidden="true"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path d="M12.48 10.92v3.28h7.84c-.24 1.84-.853 3.187-1.787 4.133-1.147 1.147-2.933 2.4-6.053 2.4-4.827 0-8.6-3.893-8.6-8.72s3.773-8.72 8.6-8.72c2.6 0 4.507 1.027 5.907 2.347l2.307-2.307C18.747 1.44 16.133 0 12.48 0 5.867 0 .307 5.387.307 12s5.56 12 12.173 12c3.573 0 6.267-1.173 8.373-3.36 2.16-2.16 2.84-5.213 2.84-7.667 0-.76-.053-1.467-.173-2.053H12.48z" />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="relative flex-1 hidden w-0 lg:block">
      <img
        class="absolute inset-0 object-cover w-full h-full"
        src="@/assets/img/backgrounds/black-temple.jpg"
        alt=""
      >
    </div>
  </div>
</template>

<script>

export default {
}
</script>
